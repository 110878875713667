// extracted by mini-css-extract-plugin
export var base = "PaginatedPosts-module--base--1TCd6";
export var rightAlignedContainer = "PaginatedPosts-module--rightAlignedContainer--2dc-_";
export var rightAlignedContainerSmall = "PaginatedPosts-module--rightAlignedContainerSmall--2_T71";
export var leftAlignedContainer = "PaginatedPosts-module--leftAlignedContainer--2m7yD";
export var blogContainer = "PaginatedPosts-module--blogContainer--3m3Wu PaginatedPosts-module--base--1TCd6";
export var container = "PaginatedPosts-module--container--9t1I2 PaginatedPosts-module--base--1TCd6";
export var largeContainer = "PaginatedPosts-module--largeContainer--2VQO9 PaginatedPosts-module--base--1TCd6";
export var mobileSmallPadding = "PaginatedPosts-module--mobileSmallPadding--3HeiY";
export var fullWidthMobile = "PaginatedPosts-module--fullWidthMobile--14xqt";
export var gridWithSidebar = "PaginatedPosts-module--gridWithSidebar--3tvb_";
export var noMaxWidth = "PaginatedPosts-module--noMaxWidth--2br4d";
export var wrap = "PaginatedPosts-module--wrap--pCxdV PaginatedPosts-module--blogContainer--3m3Wu PaginatedPosts-module--base--1TCd6";