import {
  ArticlePreview,
  InfiniteArticlesList
} from '@animoto/components';
import { graphql } from 'gatsby';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import AnimotoBlogLayout from '../components/AnimotoBlogLayout';
import BlogPageNavigation from '../components/BlogPageNavigation';
import { generatePaginatedUrls } from '../utils/paginationUtils';
import { wrap } from './PaginatedPosts.module.css';

export default function PaginatedPosts({ data, pageContext }) {
  const {
    allContentfulBlogPost : posts
  } = data;
  const {
    pageTitle,
    pathPrefix
  } = pageContext;

  const {
    nextUrl,
    previousUrl,
    currentPage,
    hasNextPage,
    hasPreviousPage
  } = generatePaginatedUrls({ pathPrefix, ...posts.pageInfo });

  const SEOInfo = {
    title         : pageTitle,
    canonicalSlug : '/blog'
  };

  const recentArticles = posts.edges.map((post) => {
    const node = post.node ? post.node : post;
    const {
      body,
      description,
      featuredImage,
      fields,
      id,
      mainCategory,
      publishedOn,
      title,
      updatedAt
    } = node;

    return (
      <ArticlePreview
        key={id}
        category={(mainCategory && mainCategory.name) || ''}
        categoryUrl={(mainCategory && mainCategory.fields && `/blog/category/${mainCategory.fields.categorySlug}`) || ''}
        imageSrcFluid={featuredImage && featuredImage.fluid}
        postUrl={`/blog/${fields.postSlug}`}
        publishDate={publishedOn || updatedAt}
        title={title}
      >
        {(description && description.childMarkdownRemark.excerpt) || (body && body.childMarkdownRemark.excerpt)}
      </ArticlePreview>
    );
  });
  return (
    <AnimotoBlogLayout SEOInfo={SEOInfo}>
      <div className={wrap}>
        <InfiniteArticlesList
          hideShowMore
          onShowMoreClick={noop}
          title={pageTitle}
        >
          {recentArticles}
        </InfiniteArticlesList>
        {
          (hasNextPage || hasPreviousPage)
          && <BlogPageNavigation currentPage={currentPage} nextUrl={nextUrl} previousUrl={previousUrl} />
        }
      </div>
    </AnimotoBlogLayout>
  );
}

export const pageQuery = graphql`
  query PaginatedPosts($skip: Int, $limit: Int!) {
    allContentfulBlogPost(
      limit: $limit,
      skip: $skip,
      filter: {
        noIndex       : { eq : false },
        noIndexOnBlog : { ne : true },
        fields        : {
          postSlug     : { ne : null },
          featuredPost : { eq : false }
        }
      },
      sort: {fields : publishedOn, order :DESC}
    ) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          title
          publishedOn
          updatedAt
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          description {
            childMarkdownRemark {
              excerpt
            }
          }
          featuredImage {
            fluid(maxWidth: 670, maxHeight: 447) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          mainCategory {
            name
            fields {
              categorySlug
            }
          }
          fields {
            postSlug
          }
        }
      }
    }
  }
`;

PaginatedPosts.propTypes = {
  data : PropTypes.shape({
    allContentfulBlogPost : PropTypes.shape({
      edges : PropTypes.arrayOf(PropTypes.shape({
        node : PropTypes.shape({
          body : PropTypes.shape({
            childMarkdownRemark : PropTypes.shape({
              excerpt : PropTypes.string
            })
          }),
          description : PropTypes.shape({
            childMarkdownRemark : PropTypes.shape({
              excerpt : PropTypes.string
            })
          }),
          featuredImage : PropTypes.shape({
            fixed : PropTypes.shape({
              src : PropTypes.string
            })
          }),
          fields : PropTypes.shape({
            postSlug : PropTypes.string
          }),
          id           : PropTypes.string,
          mainCategory : PropTypes.shape({
            fields : PropTypes.shape({
              categorySlug : PropTypes.string
            }),
            name : PropTypes.string
          }),
          publishedOn         : PropTypes.string,
          retinaFeaturedImage : PropTypes.shape({
            fixed : PropTypes.shape({
              src : PropTypes.string
            })
          }),
          title     : PropTypes.string,
          updatedAt : PropTypes.string
        })
      })),
      pageInfo : PropTypes.shape({
        hasNextPage : PropTypes.bool
      })
    })
  }).isRequired,
  pageContext : PropTypes.shape({
    index      : PropTypes.number,
    name       : PropTypes.string,
    pageTitle  : PropTypes.string,
    pathPrefix : PropTypes.string
  }).isRequired

};
